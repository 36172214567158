<template>
    <div class="equipment">
        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
            <form @submit.prevent="onSubmit" v-for="(localization, index) in form.localizations" :key="localization.language">
                <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                    <div class="d-flex">
                        <button type="button" v-if="equipmentCategoryId == -1 && index == 0" @click="$bvModal.show('equipment-categories-modal' + form.equipmentId)">assign</button>
                        <input v-if="!equipment" :ref="'equipment-input' + form.id + localization.language" type="text" :placeholder="$t('admin.addEquipment')" v-model.trim="newEquipmentForm.title" :class="{error: error.title}" maxlength="50">
                        <input v-else-if="isEditable" :ref="'equipment-input' + form.id + localization.language" type="text" v-model.trim="localization.title" :class="{error: error.title}" maxlength="50">
                        <p v-else @focus="editEquipmentTitle(localization.language)" @click="editEquipmentTitle(localization.language)">{{ localization.title }}</p>
                        <div class="d-flex align-items-center">
                            <button v-if="!isEditable && equipment" type="button" class="img-button edit-button" @click.prevent="editEquipmentTitle(localization.language)"></button>
                            <button v-else-if="equipment" class="img-button confirm-button"></button>
                            <TrekioButton v-else width="60px" style="font-size: 26px;" type="submit" primary :isLoading="isLoading">+</TrekioButton>
                            <button v-if="equipment && !isManagingLocalizations" type="button" class="img-button delete-button" @click="showDeleteEquipmentModal"></button>
                            <button type="button" :class="{active: localization.productLink}" class="img-button show-link-button" @click="toggleProductLinkInput"></button>
                        </div>
                    </div>
                    <input class="product-link-input" v-if="!equipment && isProductLinkVisible" type="text" v-model.trim="newEquipmentForm.productLink" :placeholder="$t('admin.addLink')">
                    <input class="product-link-input" v-else-if="isProductLinkVisible" type="text" @focus="isEditable = true" v-model.trim="localization.productLink" :placeholder="$t('admin.addLink')">
                </div>
            </form>
        </div>

        <b-modal v-if="equipmentCategoryId" size="fit-content" body-class="p-0" class="modal" :id="'equipment-categories-modal' + form.equipmentId" hide-footer hide-header>
            <p role="button" @click="updateEquipmentCategory(category)" class="category-title" v-for="category in equipmentCategories" :key="category.id">{{ category.localizations[0].title }}</p>
        </b-modal>

        <b-modal class="modal" :id="'delete-equipment-modal' + form.equipmentId" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                <h3 class="moda-title pb-3">{{ $t("modals.equipmentDeleteTitle", [form.localizations[0].title])}}"?</h3>
                <div class="pb-5 d-flex" style="gap: 40px">
                    <TrekioButton secondary :disabled="loadingDelete != ''"  @click="$bvModal.hide('delete-equipment-modal' + form.equipmentId)">{{ $t("buttons.back") }}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="deleteEquipment">{{ $t("buttons.delete") }}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import EquipmentService from '../../services/EquipmentService';
    import sharedUtils from '../../utils/sharedUtils';

    export default {
        props: {
            equipment: {
                type: Object,
            },
            equipmentCategoryId: {
                required: true
            },
            isManagingLocalizations: {
                type: Boolean,
                required: true
            },
            primaryLanguage: {
                type: String
            },
            editedLanguage: {
                type: String
            }
        },
        data() {
            return {
                isProductLinkVisible: false,
                isEditable: false,
                newEquipmentForm: {
                    title: "",
                    productLink: "",
                },
                form: {
                    equipmentId: null,
                    categoryId: null,
                    originalLanguage: "",
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: "",
                            productLink: "",
                        }
                    })
                },
                lastSavedForm: null,
                error: {
                    title: '',
                },
                isLoading: false,
                loadingDelete: '',
            }
        },

        created() {
            this.form.categoryId = this.equipmentCategoryId

            if (!this.equipment) return
            this.form.equipmentId = this.equipment.id
            this.form.categoryId = this.equipment.category?.id
            this.form.originalLanguage = this.equipment.originalLanguage
            this.form.localizations = this.equipment.localizations.map(localization => {
                const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                delete localizationCopy['__typename'];
                return localizationCopy
            })
            this.sortLocalizations()
            this.lastSavedForm = this.createCopyOfForm(this.form)
        },

        watch: {
            primaryLanguage() {
                this.sortLocalizations()
            },
            editedLanguage() {
                this.sortLocalizations()
            }
        },

        computed: {
            equipmentCategories() {
                return this.$store.state.admin.equipmentCategories
            }
        },

        methods: {
            updateEquipmentCategory(category) {
                this.form.categoryId = category.id
                this.updateEquipment(category)
            },
            isChangeInForms() {
                console.log(this.lastSavedForm, this.form)
                if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) return true
                return false  
            },
            createCopyOfForm(form) {
                return {
                    ...form,
                    localizations: form.localizations.map(localization => ({...localization}))
                }
            },
            toggleProductLinkInput() {
                this.isProductLinkVisible = !this.isProductLinkVisible
            },
            editEquipmentTitle(localizationLanguageCode) {
                this.isEditable = true
                this.$nextTick(() => {
                    const equipmentInputRefName = 'equipment-input' + this.form.id + localizationLanguageCode
                    this.$refs[equipmentInputRefName][0].focus()
                })
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
                if (this.lastSavedForm) {
                    sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
                }
            },
            showDeleteEquipmentModal() {
                this.$bvModal.show('delete-equipment-modal' + this.form.equipmentId)
            },
            onSubmit() {
                if (!this.equipment) {
                    this.createEquipment()
                } else {
                    this.updateEquipment()
                }
            },
            async createEquipment() {
                if (this.isLoading) return
                this.error.title = false
                if (!this.newEquipmentForm.title) { 
                    return this.error.title = true
                }
                this.form.localizations = this.form.localizations.map(localization => {
                    return {
                        language: localization.language,
                        title: localization.language == this.primaryLanguage ? this.newEquipmentForm.title : "",
                        productLink: localization.language == this.primaryLanguage ? this.newEquipmentForm.productLink : ""
                    }
                })
                this.form.originalLanguage = this.primaryLanguage
                this.isLoading = true
                await EquipmentService.createEquipment(this.form)
                    .then(() => {
                        this.newEquipmentForm.title = ''
                        this.newEquipmentForm.productLink = ''
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentCreated"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentCreateError"))
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.form.title = ''
                    })
            },
            async updateEquipment(category = null) {
                if (this.isLoading) return
                this.error.title = false
                if (!this.form.localizations[0].title) {
                    return this.error.title = true
                }

                this.isLoading = true
                await EquipmentService.updateEquipment(this.form, category)
                    .then(resp => {
                        this.isEditable = false
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentUpdated"))
                        this.lastSavedForm = this.createCopyOfForm(this.form)
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentUpdateError"))
                        console.log(err)
                    })
                    .finally(() => this.isLoading = false)
            },
            async deleteEquipment() {
                this.loadingDelete = this.$t("loading.delete")
                await EquipmentService.deleteEquipment(this.form.equipmentId)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentDeleted"))
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentDeleteError"))
                        console.log(err)
                    })
                    .finally(() => this.loadingDelete = '')
            }
        },
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .category-title {
        padding: 20px;

        &:hover {
            background-color: $tr-green;
        }
    }

    .forms-container {
        width: 100%;
        column-gap: 30px;
        flex-wrap: wrap;

        form {
            width: calc(50% - 15px);
            flex: 0 0 calc(50% - 15px);
            
            .w-50 & {
                width: 100%;
            }
        }
        .form-content-container {
            display: flex;
            align-items: space-between;
            flex-direction: column;
            justify-content: center;
            background-color: $tr-white-alt;

            .product-link-input {
                color: $tr-green-alt-link
            }
        }
    }

    p {
        margin: 0;
    }
    .equipment {
        width: 100%;

        p {
            cursor: pointer;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            padding-left: 19.5px;
            width: 100%;
            line-height: 60px;
            height: 60px;
            padding-top: 1px;
            overflow: hidden;
            text-overflow: ellipsis;

            &:focus {
                border: 2px solid $tr-green
            }
        }

        input {
            width: 100%;
            resize: none;
            padding: 0;
            background-color: $tr-white-alt;
            color: $tr-black;
            line-height: 20px;
            padding-left: 18px;
            height: 60px;
            border: 2px solid transparent !important;
            
            &:focus {
                border: 2px solid $tr-green-alt-link !important;
            }

            &.error, &.error:focus {
                border: 2px solid $tr-pink !important;
            }
        }

        .img-button {
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            width: 60px;
            height: 60px;

            &:hover {
                background-color: $tr-light-gray;
            }
        }

        .confirm-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
        }

        .delete-button {
            background-size: 14px;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
        }

        .edit-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
        }

        .show-link-button {
            height: 60px;
            width: 60px;
            background-color: $tr-white;
            border: 1px solid $tr-black;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23222"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23222"/></svg>');
            
            &:hover {
                background-color: $tr-black;
                background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23fff"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23fff"/></svg>');
                
            }
            
            &.active {
                border: 1px solid $tr-green;
                background-color: $tr-green;
                background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23fff"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23fff"/></svg>');
            }
            
        }
    }

</style>