<template>
    <div style="padding-bottom: 80px;">
        <div class="d-flex align-items-center mb-4">
            <span class="arrow arrow--left" @click="$emit('hideManageTemplate')"></span>
            <h1>{{ form.localizations[0].title }}</h1>
        </div>
        <div v-for="category in equipmentCategories" :key="category.id">
            <a class="category-collapse" v-b-toggle="'equipment-category' + category.id">
                <p >{{ category.localizations && category.localizations[0].title }} ({{ category.equipments && category.equipments.map(eq => parseInt(eq.id)).filter(eqId => form.equipmentIds.includes(eqId)).length }})</p>
            </a>
            <b-collapse :id="'equipment-category' + category.id">
                <div class="equipment-list">
                    <div class="equipment-container" v-for="equipment in category.equipments" :key="equipment.id">
                        <div class="d-flex w-100">
                            <div class="checkbox-with-title pr-3">
                                <Checkbox @change="(event) => addOrRemoveEquipmentId(equipment.id, event.target.checked)" :value="form.equipmentIds.includes(parseInt(equipment.id))"/>
                                <p class="ml-3">{{ equipment.localizations && equipment.localizations[0].title }}</p>
                            </div>
                            <button type="button" :class="{active: equipment.localizations[0].productLink, disabled: !equipment.localizations[0].productLink}" class="img-button show-link-button" @click.stop="showOrHideLink($event)"></button>
                        </div>
                        <div class="product-link-row d-flex w-100">
                            <p class="product-link">{{ equipment.localizations[0].productLink }}</p>
                            <button type="button" class="img-button copy-link-button" @click.stop="copyLink(equipment.localizations[0].productLink)"></button>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
        <div class="button-container" :class="{'full-width': !isSidebarVisible}">
            <TrekioButton primary :isLoading="loading != ''" :loadingText="loading" @click="onSubmit">{{ $t("buttons.saveTemplate") }}</TrekioButton>
        </div>
    </div>

</template>

<script>
import Checkbox from '../Checkbox.vue';
import EquipmentService from '../../services/EquipmentService';
import sharedUtils from '../../utils/sharedUtils';

    export default {
        props: {
            template: {
                type: Object,
            },
            equipmentCategories: {
                type: Array
            },
            primaryLanguage: {
                type: String
            },
            editedLanguage: {
                type: String
            },
            isManagingLocalizations: {
                type: Boolean
            }
        },

        components: {
            Checkbox
        },

        data() {
            return {
                loading: "",
                loadingDelete: "",
                form: {
                    equipmentIds: [],
                    originalLanguage: "",
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: ""
                        }
                    })
                }
            }
        },

        watch: {
            template(nV) {
                this.resetToDefault()
            },
            primaryLanguage() {
                this.sortLocalizations()
            }
        },

        computed: {
            isSidebarVisible() {
                return this.$store.getters.getIsSidebarVisible
            },
        },

        methods: {
            showOrHideLink(event) {
                event.target.parentElement.parentElement.classList.toggle('link-visible')
            },
            copyLink(link) {
                navigator.clipboard.writeText(link)
            },
            async deleteEquipmentTemplate() {
                this.loadingDelete = this.$t("loading.equipmentTemplateDelete")
                await EquipmentService.deleteEquipmentTemplate(this.form.equipmentTemplateId)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentTemplateDeleted"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentTemplateDeleteError"))
                    })
                    .finally(() => this.loadingDelete = '')
            },
            async onSubmit() {
                // if (!this.validateInputs()) return

                this.loading = this.$t("loading.equipmentTemplateUpdate")
                await EquipmentService.updateEquipmentTemplate(this.form)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentTemplateUpdated"))
                        this.isTemplateTitleEditable = false
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentTemplateUpdateError"))
                    })
                    .finally(() => this.loading = '')
            },
            addOrRemoveEquipmentId(equipmentId, isChecked) {
                const equipmentIdAsNumber = parseInt(equipmentId)
                if (isChecked) {
                    this.form.equipmentIds.push(equipmentIdAsNumber)
                    return
                }
                const index = this.form.equipmentIds.indexOf(equipmentIdAsNumber)
                if (index != - 1) {
                    this.form.equipmentIds.splice(index, 1)
                }
            },
            resetToDefault() {
                this.form.equipmentTemplateId = this.template.id
                this.form.equipmentIds = this.template.equipments.map(eq => parseInt(eq.id))
                this.form.originalLanguage = this.template.originalLanguage
                this.form.localizations = this.template.localizations.map(localization => {
                    const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                    delete localizationCopy['__typename'];
                    return localizationCopy
                })
                this.sortLocalizations()
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
            }
        },
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    p {
        margin: 0;
    }
    
    .category-collapse {
        font-size: 25px;
        line-height: 25px;
        color: $tr-black;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: dotted 3px $tr-light-gray;
        border-top: dotted 3px $tr-light-gray;
        height: 80px;
        cursor: pointer;
        margin-top: -3px;

        p {
            font-size: 25px;
            width: 100%;
            line-height: 60px;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            padding-top: 1px;
            padding-left: 39.5px;
            margin: 0;
        }

        &:hover{
            color: $tr-black;
            text-decoration: none;
        }

        &::after{
            content: "";
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 14px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            background-size: 24px 15px;
        }

        &[aria-expanded="true"]{
            &::after{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            }
        }

    }

    .equipment-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-block: 20px 30px;
    }

    .equipment-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $tr-white-alt;
        width: 50%;
        padding-left: 20px;

        .checkbox-with-title {
            display: flex;
            height: 60px;
            align-items: center;
            width: calc(100% - 60px);

            p {
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
            }
        }
        .product-link-row {
            display: none !important;


            .product-link {
                height: 60px;
                width: 100%;
                line-height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.link-visible .product-link-row {
            display: flex !important;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        max-width: calc(100% - 300px);
        width: 100%;
        bottom: 0;
        left: 300px;
        background-color: $tr-white;
        border-top: 1px solid $tr-light-gray;;
        position: fixed;
        z-index: 1030;
        transition: 0.3s ease-in-out;
        transition-property: left, max-width;
        padding: 10px 40px;

        &.full-width {
            max-width: 100%;
            left: 0
        }
    }

    .img-button {
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 60px;
        height: 60px;
        flex-shrink: 0;

        &:hover {
            background-color: $tr-light-gray;
        }
    }

    .confirm-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
    }

    .delete-button {
        background-size: 14px;
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
    }

    .edit-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
    }

    .show-link-button {
        background-color: $tr-white;
        border: 1px solid $tr-black;
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23222"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23222"/></svg>');
        
        &:hover {
            background-color: $tr-black;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23fff"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23fff"/></svg>');
            
        }
        
        &.active {
            border: 1px solid $tr-green;
            background-color: $tr-green;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.19 11.415a3.482 3.482 0 0 0-5.637-1.029L5.6 13.342a3.482 3.482 0 1 0 4.925 4.925l1.723-1.724" stroke="%23fff"/><path d="M9.895 12.49a3.482 3.482 0 0 0 5.55.851l2.955-2.954a3.482 3.482 0 0 0-4.924-4.925l-1.724 1.724" stroke="%23fff"/></svg>');
        }

        &.disabled {
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none;
        }
        
    }

    .copy-link-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M9.6 2v2.4l.8.8V2.8h6v4.8h4.8V18h-6v.8H22V7.037L16.962 2zm7.6 1.362L20.638 6.8H17.2zM2 5.2V22h12.4V10.238l-.113-.125-4.8-4.8-.124-.113zm.8.8h6v4.8h4.8v10.4H2.8zm6.8.575L13.025 10H9.6z" fill="%23222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');

        &:active {
            background-color: $tr-white-alt;
        }
    }

</style>