<template>
    <div class="template-container" :class="{'mb-40': !template}" @click="$emit('manageTemplate', template)">
        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
            <form v-for="localization in form.localizations" :key="localization.language" @submit.prevent="onSubmit" :class="{'width-50': isManagingLocalizations}">
                <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                    <input v-if="!template" type="text" style="font-size: 25px;" v-model="newEquipmentTemplateTitle" :placeholder="$t('admin.newTemplateTitle')" :class="{error: error.title}" @click.stop="">
                    <input v-else-if="isTemplateTitleEditable" type="text" v-model="localization.title" :placeholder="$t('inputLabels.categoryTitle')" :class="{error: error.title}" @click.stop="">
                    <p v-else>{{ localization.title }}</p>
                    <TrekioButton v-if="!template" width="80px" height="80px" style="font-size: 26px;" type="submit" primary :isLoading="loading != ''">+</TrekioButton>
                    <button v-else-if="!isTemplateTitleEditable" type="button" class="img-button edit-button ml-auto" @click.stop="editTemplateTitle(localization.language)"></button>
                    <button v-else type="button" class="img-button confirm-button ml-auto" @click.stop="onSubmit()"></button>
                    <button v-if="template && !isManagingLocalizations" type="button" class="img-button delete-button" @click.stop="$bvModal.show('delete-equipment-template-modal' + form.equipmentTemplateId)"></button>
                </div>
            </form>
        </div>
        <b-modal class="modal" :id="'delete-equipment-template-modal' + form.equipmentTemplateId" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                <h3 class="moda-title pb-3">{{ $t("modals.equipmentTemplateDeleteTitle", [form.title])}}</h3>
                <div class="pb-5 d-flex" style="gap: 40px">
                    <TrekioButton secondary :disabled="loadingDelete != ''"  @click="$bvModal.hide('delete-equipment-template-modal' + form.equipmentTemplateId)">{{ $t("buttons.back") }}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="deleteEquipmentTemplate">{{ $t("buttons.delete") }}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>

</template>

<script>
import EquipmentService from '../../services/EquipmentService';
import sharedUtils from '../../utils/sharedUtils';

    export default {
        props: {
            template: {
                type: Object,
                required: false,
            },
            categoriesWithEquipment: {
                type: Array,
                required: true
            },
            isManagingLocalizations: {
                type: Boolean,
            },
            primaryLanguage: {
                type: String,
                required: true
            },
            editedLanguage: {
                type: String
            }
        },
        data() {
            return {
                isTemplateTitleEditable: false,
                newEquipmentTemplateTitle: "",
                form: {
                    equipmentTemplateId: null,
                    originalLanguage: "cs",
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: ""
                        }
                    }),
                    equipmentIds: []
                },
                lastSavedForm: null,
                error: {
                    title: ''
                },
                loading: '',
                loadingDelete: '',
            }
        },

        created() {
            if (this.template) {
                this.resetToDefault()
            }
            this.sortLocalizations()
        },

        watch: {
            primaryLanguage() {
                this.sortLocalizations()
            },
            editedLanguage() {
                this.sortLocalizations()
            }
        },

        methods: {
            isChangeInForms() {
                if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) return true
                return false  
            },
            createCopyOfForm(form) {
                return {
                    ...form,
                    localizations: form.localizations.map(localization => ({...localization}))
                }
            },
            editTemplateTitle() {
                this.isTemplateTitleEditable = true
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
                if (this.lastSavedForm) {
                    sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
                }
            },
            onSubmit() {
                if (!this.template) {
                    this.createEquipmentTemplate()
                } else {
                    this.updateEquipmentTemplate()
                }
            },

            validateInputs() {
                this.error.title = ''
                const inputValue = !this.template ? this.newEquipmentTemplateTitle : this.form.localizations[0].title 
                if (!inputValue) {
                    this.error.title = this.$t("inputErrors.required")
                    return false
                }
                return true
            },

            async createEquipmentTemplate() {
                if (this.loading || !this.validateInputs()) return
                this.form.originalLanguage = this.primaryLanguage
                this.form.localizations = this.form.localizations.map(localization => {
                    return {
                        language: localization.language,
                        title: localization.language == this.primaryLanguage ? this.newEquipmentTemplateTitle : ""
                    }
                })
                this.loading = this.$t("loading.equipmentTemplateCreate")
                await EquipmentService.createEquipmentTemplate(this.form)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentTemplateCreated"))
                        this.isTemplateTitleEditable = false
                        this.newEquipmentTemplateTitle = ""
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentTemplateCreateError"))
                    })
                    .finally(() => {
                        this.loading = ''
                        this.form.title = ''
                        this.form.equipmentIds = []
                    })
            },
            async updateEquipmentTemplate() {
                if (this.loading || !this.validateInputs()) return

                this.loading = this.$t("loading.equipmentTemplateUpdate")
                await EquipmentService.updateEquipmentTemplate(this.form)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentTemplateUpdated"))
                        this.isTemplateTitleEditable = false
                        this.lastSavedForm = this.createCopyOfForm(this.form)
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentTemplateUpdateError"))
                    })
                    .finally(() => this.loading = '')
            },
            async deleteEquipmentTemplate() {
                this.loadingDelete = this.$t("loading.equipmentTemplateDelete")
                await EquipmentService.deleteEquipmentTemplate(this.form.equipmentTemplateId)
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentTemplateDeleted"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.$emit('showAlertDangerEquipment', this.$t("alerts.equipmentTemplateDeleteError"))
                    })
                    .finally(() => this.loadingDelete = '')
            },
            addOrRemoveEquipmentId(equipmentId) {
                if (this.form.equipmentIds.includes(equipmentId)) {
                    this.form.equipmentIds = this.form.equipmentIds.filter(formEquipmentId => formEquipmentId != equipmentId)
                } else {
                    this.form.equipmentIds.push(equipmentId)
                }
            },
            resetToDefault() {
                this.form.equipmentTemplateId = this.template.id
                this.form.originalLanguage = this.template.originalLanguage
                this.form.localizations = this.template.localizations.map(localization => {
                    const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                    delete localizationCopy['__typename'];
                    return localizationCopy
                })
                this.form.equipmentIds = this.template.equipments.map(equipment => equipment.id)
                this.lastSavedForm = this.createCopyOfForm(this.form)
            },
        },
    }

</script>


<style lang="scss" scoped>
    @import '@/scss/variables';

    p {
        margin: 0;
    }

    .template-container {
        cursor: pointer;

        &.mb-40 {
            margin-bottom: 40px;
        }
        &:nth-child(1), &:nth-child(2) {
            border-top: 3px dashed $tr-light-gray;
        }

        .forms-container {
            width: 100%;
            column-gap: 30px;
            flex-wrap: wrap;


            form {
                display: flex;
                gap: 20px;
                justify-content: space-between;
                
                &.width-50 {
                    width: calc(50% - 15px);
                    flex: 0 0 calc(50% - 15px);
                }

                .form-content-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 80px;
                    border-bottom: 3px dashed $tr-light-gray;
                    


                    &:hover {
                        background-color: $tr-white-alt;

                    }
                }
    
                p {
                    font-size: 25px;
                    width: 100%;
                    line-height: 60px;
                    overflow: hidden;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 1px;
                    padding-left: 39.5px;
                }
    
                input {
                    width: 100%;
                    height: 100%;
                    padding-left: 38px;
                    border: 2px solid transparent;

                    &:focus {
                        border: 2px solid $tr-green-alt-link !important;
                    }

                    &.error, &.error:focus {
                        border: 2px solid $tr-pink !important;
                    }
                }
    
                &:hover{
                    color: $tr-black;
                    text-decoration: none;
                }
        
                .img-button {
                    background-size: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 80px;
                    height: 80px;
                    aspect-ratio: 1;

                    &:hover {
                        background-color: $tr-light-gray;
                    }
                }
    
                .confirm-button {
                    background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
                }
    
                .delete-button {
                    background-size: 14px;
                    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
                }
    
                .edit-button {
                    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
                }
    
            }
        }
    }


</style>